<template>
  <div class="page">
    <div class="userTypeTitle">房号选择(必填)</div>
    <div class="withe_box">
      <!-- <div class="selectUserType_box">
        <div
          class="item"
          @click="changeUserType(item)"
          v-for="(item, index) in categoryList"
          :key="index"
        >
          <div :class="['checkBox', { checked: item.isSelect == true }]"></div>
          <div class="userTypeName">{{ item.value }}</div>
        </div>
      </div> -->
      <!-- 小区 -->
      <div
        class="item_box"
        v-for="(item, index) in this.selectList"
        :key="index"
        @click="selectValue(item, index)"
      >
        <div class="left_box">
          {{ index == 0 ? item.title + "(必填)" : item.title }}
        </div>
        <div class="right_box">{{ item.text || "请选择" }}</div>
      </div>
    </div>
    <!-- 返回按钮 -->
    <div class="btn_box">
      <div class="btn" @click="back">编辑并返回</div>
    </div>
    <v-picker
      :columns="list"
      :valueShow="show"
      :valueKey="eventKey"
      @clickOverlay="clickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirm"
    ></v-picker>
  </div>
</template>

<script>
import { areaLinkUrl } from "./api.js";
export default {
  name: "editPlace",
  data() {
    return {
      categoryList: [
        {
          key: 1,
          value: "居民",
          isSelect: true,
        },
        {
          key: 2,
          value: "企业/商户",
          isSelect: false,
        },
      ],
      selectList: [],
      idList: [],
      textList: [],

      list: [],
      show: false,
      eventKey: "text",

      itemIndexNum: 0,
      code: "",
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.getItemData();
  },
  mounted() {},
  methods: {
    back() {
      if (!this.selectList[0].text) {
        this.$toast(`请选择${this.selectList[0].title}`);
        return;
      }
      let list = [];
      this.selectList.forEach((item) => {
        console.log("item----->", item);
        if (item.text) {
          list.push(item.text);
        }
      });
      console.log("list----->", list.join("-"));
      this.$router.replace({
        name: this.$route.query.path,
        query: {
          place: list.join("-"),
          code: this.code,
        },
      });
    },
    selectValue(item, index) {
      this.show = true;
      this.list = item.list;
      this.itemIndexNum = index;
    },
    clickOverlay() {
      this.show = false;
    },
    cancelPick() {
      this.show = false;
    },
    async confirm(value) {
      console.log(value);
      console.log(this.selectList);
      this.selectList.splice(this.itemIndexNum + 1);
      this.selectList[this.itemIndexNum].text = value.text;
      this.code = value.code;
      await this.getItemData(value.code);
      this.show = false;
    },
    async getItemData(parentId = "P1C2A2V1", userTypeId) {
      let params = {
        parentId: parentId || this.tenantId,
        specifyCollectionTypes: userTypeId || 1,
        tenantId: this.tenantId,
      };
      let res = await this.$axios.get(`${areaLinkUrl}`, {
        params: params,
      });
      if (res.code === 200) {
        if (Object.getOwnPropertyNames(res.data).length == 0) {
        } else {
          this.selectList.push(res.data);
          console.log(this.selectList);
        }
      }
    },
    changeUserType(data) {
      this.categoryList.forEach((item) => {
        item.isSelect = false;
      });
      data.isSelect = true;
      this.selectList = [];
      this.getItemData(null, data.key);
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;
  padding: 28px;
  box-sizing: border-box;
  background: #fafafa;

  .btn_box {
    width: 100%;
    height: 66px;
    padding: 0 100px;
    box-sizing: border-box;
    position: fixed;
    bottom: 120px;
    left: 0;

    .btn {
      width: 100%;
      height: 100%;
      background: #fe4416;
      border-radius: 10px;
      text-align: center;
      line-height: 66px;
      font-size: 32px;
      font-weight: 600;
      color: #ffffff;
    }
  }
  .withe_box {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(218, 216, 216, 0.5);
    border-radius: 16px;
    padding: 30px;
    box-sizing: border-box;
    .item_box {
      width: 100%;
      line-height: 100px;
      height: 100%;
      font-size: 30px;
      display: flex;

      &:not(:first-child) {
        border-top: 1px solid #eeeeee;
      }

      .left_box {
        width: 200px;
        height: 100%;
        color: #333333;
      }

      .right_box {
        text-align: right;
        flex: 1;
        height: 100%;
        font-size: 26px;
        color: rgba(0, 0, 0, 0.5);
      }
    }

    .selectUserType_box {
      width: 100%;
      height: 100px;
      display: flex;

      .item {
        flex: 1;
        display: flex;
        align-items: center;

        .checkBox {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 1px solid #307dff;
          box-sizing: border-box;
          margin-right: 10px;
        }

        .checked {
          background-color: #307dff;
        }

        .userTypeName {
          white-space: nowrap;
          font-size: 30px;
          color: #333333;
        }
      }
    }
  }

  .userTypeTitle {
    font-size: 32px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 38px;
  }
}
</style>
